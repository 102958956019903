import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["input", "name"];

  connect() {}

  change() {
    if (this.inputTarget.files.length > 0) {
      var file_names = [];
      var i = 0;
      for (i = 0; i < this.inputTarget.files.length; ++i) {
        file_names.push(this.inputTarget.files[i].name);
      }
      this.nameTarget.innerHTML = file_names.join(", ");
    }
  }
}
