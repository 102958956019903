import { Controller } from "stimulus"
import { EmojiButton } from '@joeattardi/emoji-button';

export default class extends Controller {
  static targets = [ "button", "input" ]

  connect() {
    var _this = this
    this.currentPosition = 0
    this.picker = new EmojiButton()
    this.picker.on('emoji', emoji => {
      _this.insertEmoji(emoji.emoji)
    })
  }

  insertEmoji(emoji) {
    // this.inputTarget.value = emoji.emoji

    if (this.inputTarget.selectionStart || this.inputTarget.selectionStart == '0') {
        var startPos = this.inputTarget.selectionStart;
        var endPos = this.inputTarget.selectionEnd;
        this.inputTarget.value = this.inputTarget.value.substring(0, startPos)
            + emoji
            + this.inputTarget.value.substring(endPos, this.inputTarget.value.length);
    } else {
      this.inputTarget.value += myValue;
    }
    this.inputTarget.focus()
  }

  toggle(event) {
    event.preventDefault()
    this.picker.togglePicker(event.target)
  }
}