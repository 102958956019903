import { Controller } from "stimulus"
import Sortable from "sortablejs"
import Rails from "@rails/ujs"

export default class extends Controller {
  connect() {
    this.sortable = Sortable.create(this.element, {
      group: 'shared',
      animation: 150,
      onEnd: this.end.bind(this)
    })
  }

  end(event) {
 
    let itemId =  event.item.dataset.itemId
    let data = new FormData()
    data.append("position", event.newIndex + 1)
    var item = event.item


    Rails.ajax({
      url: this.data.get("url").replace(":item_id", itemId),
      type: 'PATCH',
      data: data,
      success: function(data) {
        if (item.tagName == 'TR') {
          for (const [index, value] of Object.entries(event.item.parentElement.getElementsByClassName("position"))) {
            value.innerHTML = (parseInt(index) + 1)
          }
        }
      },
    })
  }
}