import Pickr from "@simonwep/pickr/dist/pickr.es5.min";
import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["input", "colorField", "style"];
  static values = { x: Number, y: Number };

  connect() {
    var _this = this;
    // console.log(_this.styleTargets)
    const pickr = new Pickr({
      el: _this.colorFieldTarget,
      useAsButton: true,
      default: _this.inputTarget.value,
      theme: "nano",
      lockOpacity: false,

      swatches: [
        "rgba(244, 67, 54, 1)",
        "rgba(233, 30, 99, 1)",
        "rgba(156, 39, 176, 1)",
        "rgba(103, 58, 183, 1)",
        "rgba(63, 81, 181, 1)",
        "rgba(33, 150, 243, 1)",
        "rgba(3, 169, 244, 1)",
        "rgba(0, 188, 212, 1)",
        "rgba(0, 150, 136, 1)",
        "rgba(76, 175, 80, 1)",
        "rgba(139, 195, 74, 1)",
        "rgba(205, 220, 57, 1)",
        "rgba(255, 235, 59, 1)",
        "rgba(255, 193, 7, 1)",
      ],

      components: {
        preview: true,
        opacity: false,
        hue: true,

        interaction: {
          hex: false,
          rgb: true,
          hsva: false,
          input: true,
          save: false,
        },
      },
    })
      .on("init", (pickr) => {
        _this.inputTarget.value = pickr.getSelectedColor().toHEXA().toString(0);
      })
      .on("change", (color, source, instance) => {
        _this.inputTarget.value = color.toHEXA().toString(0);
        _this.colorFieldTarget.style.backgroundColor = color
          .toHEXA()
          .toString(0);
        _this.styleTargets.forEach(function (item, index) {
          var paths = item.querySelectorAll("path");
          var i = 0;
          for (i = 0; i < paths.length; ++i) {
            paths[i].setAttribute(
              "style",
              `fill: ${color.toHEXA().toString(0)} !important`
            );
          }
        });
      });
  }
}
