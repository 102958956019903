import interact from 'interactjs'

import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "x", "y", "width", "color" ]
  static values = { x: Number, y:Number }

  connect() {
    var _this = this
    interact('.draggable')
      .draggable({
        // enable inertial throwing
        inertia: true,
        // keep the element within the area of it's parent
        modifiers: [
          interact.modifiers.restrictRect({
            restriction: 'parent',
            endOnly: true
          })
        ],
        // enable autoScroll
        autoScroll: true,

        listeners: {
          // call this function on every dragmove event
          move: dragMoveListener,

          // call this function on every dragend event
          end (event) {
            var textEl = event.target.querySelector('p')

            textEl && (textEl.textContent =
              'moved a distance of ' +
              (Math.sqrt(Math.pow(event.pageX - event.x0, 2) +
                        Math.pow(event.pageY - event.y0, 2) | 0))
                .toFixed(2) + 'px')
          }
        }
      })

      .resizable({
        edges: { top: true, left: true, bottom: true, right: true },
        preserveAspectRatio: true,
        modifiers: [
          interact.modifiers.aspectRatio({
            // make sure the width is always double the height
            ratio: 'preserve',

            // leider noch ein Bug.
            // https://github.com/taye/interact.js/issues/804
            
            // also restrict the size by nesting another modifier
            // modifiers: [
            //   interact.modifiers.restrictSize({ max: 'parent' })
            // ]
          })
        ],
        listeners: {
          move: function (event) {
            let { x, y } = event.target.dataset
    
            x = (parseFloat(x) || 0) + event.deltaRect.left
            y = (parseFloat(y) || 0) + event.deltaRect.top
    
            Object.assign(event.target.style, {
              width: `${event.rect.width}px`,
              height: `${event.rect.height}px`,
              transform: `translate(${x}px, ${y}px)`
            })

            _this.widthTarget.value = event.rect.width
            _this.xTarget.value = _this.xValue + x
            _this.yTarget.value = _this.yValue + y

        

            Object.assign(event.target.dataset, { x, y })
          }
        }
      })

    function dragMoveListener (event) {
      var target = event.target
      // keep the dragged position in the data-x/data-y attributes
      var x = (parseFloat(target.getAttribute('data-x')) || 0) + event.dx
      var y = (parseFloat(target.getAttribute('data-y')) || 0) + event.dy

      // translate the element
      target.style.webkitTransform =
        target.style.transform =
          'translate(' + x + 'px, ' + y + 'px)'

      // update the posiion attributes
      target.setAttribute('data-x', x)
      target.setAttribute('data-y', y)

      _this.xTarget.value = _this.xValue + x
      _this.yTarget.value = _this.yValue + y
    
    }

    // this function is used later in the resizing and gesture demos
    window.dragMoveListener = dragMoveListener
  }
}
