import { Controller } from "stimulus"
import Rails from "@rails/ujs"

export default class extends Controller {
  onPostSuccess(event) {
    location.reload();
    return false;
  }
  submit(event){
    Rails.fire(this.element, 'submit');
    event.preventDefault();
  }
}