// Visit The Stimulus Handbook for more details
// https://stimulusjs.org/handbook/introduction
//
// This example controller works with specially annotated HTML like:
//
// <div data-controller="hello">
//   <h1 data-target="hello.output"></h1>
// </div>
import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["input", "prev", "next", "radio", "style"];

  connect() {
    // console.log(this.styleTargets)
  }

  prev() {
    var i = this.radioTargets.indexOf(this.checked_radio_button());
    this.radioTargets[
      i == 0 ? this.radioTargets.length - 1 : i - 1
    ].checked = true;
    this.show_current_style(
      this.radioTargets.indexOf(this.checked_radio_button())
    );
  }

  next() {
    var i = this.radioTargets.indexOf(this.checked_radio_button());
    this.radioTargets[
      i == this.radioTargets.length - 1 ? 0 : i + 1
    ].checked = true;
    this.show_current_style(
      this.radioTargets.indexOf(this.checked_radio_button())
    );
  }

  checked_radio_button() {
    var checked = null;
    for (var radio of this.radioTargets) {
      if (radio.checked) {
        checked = radio;
        break;
      }
    }
    return checked;
  }

  show_current_style(number) {
    for (var svg of this.styleTargets) {
      svg.classList.remove("active");
    }
    this.styleTargets[number].classList.add("active");
  }
}
