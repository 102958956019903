import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["close"];

  connect() {}

  remove() {
    this.element.remove();
  }
}
