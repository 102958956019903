import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["input"];

  connect() {


    this.generateCounterElements()
    this.updateCounter(this.inputTarget.value.length)
    

    var _this = this
    this.inputTarget.addEventListener("input", function(){
      _this.updateCounter()
    });
  }

  updateCounter() {
    var chars_count = this.inputTarget.value.length
    this.char_counter_value.innerHTML = chars_count
    if(chars_count > 2200) {
      this.platform_ig.classList.add("too_much")
    } else {
      this.platform_ig.classList.remove("too_much")
    }

    if(chars_count > 1100) {
      this.platform_li.classList.add("too_much")
    } else {
      this.platform_li.classList.remove("too_much")
    }

    if(chars_count > 3000) {
      this.platform_fb.classList.add("too_much")
    } else {
      this.platform_fb.classList.remove("too_much")
    }


  }

  generateCounterElements () {
    this.platform_ig = this.htmlToElement('<span class="ig char-counter--platform">Instagram</span>');
    this.platform_li = this.htmlToElement('<span class="li char-counter--platform">LinkedIn</span>');
    this.platform_fb = this.htmlToElement('<span class="fb char-counter--platform">Facebook</span>');
    this.char_counter_value = this.htmlToElement('<span class="char-counter--value"></span>');

    this.char_counter = this.htmlToElement('<span class="char-counter"></span>');
    this.char_counter.appendChild(this.char_counter_value);
    this.char_counter.appendChild(this.platform_ig);
    this.char_counter.appendChild(this.platform_li);
    this.char_counter.appendChild(this.platform_fb);

    this.inputTarget.appendChild(this.char_counter);
    this.insertAfter(this.inputTarget, this.char_counter);
  }

  insertAfter(referenceNode, newNode) {
    referenceNode.parentNode.insertBefore(newNode, referenceNode.nextSibling);
  }

  htmlToElements(html) {
    var template = document.createElement('template');
    template.innerHTML = html;
    return template.content.childNodes;
  }

  htmlToElement(html) {
    var template = document.createElement('template');
    html = html.trim(); // Never return a text node of whitespace as the result
    template.innerHTML = html;
    return template.content.firstChild;
  }
  
}