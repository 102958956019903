import { Controller } from "stimulus"
import Rails from "@rails/ujs"

export default class extends Controller {
 
  connect(){
    
  }

  delete(event){
    // Rails.fire(this.element, 'submit');
    this.element.parentElement.parentElement
    this.element.parentNode.parentNode.parentNode.parentNode.removeChild(this.element.parentNode.parentNode.parentNode);
    
  }
}