import { Controller } from "stimulus";

import Tagify from "@yaireo/tagify";

export default class extends Controller {
  static targets = ["tagify", "tagNames"];
  static values = {
    whitelist: Array,
  };

  connect() {
    this.tagify = new Tagify(this.tagifyTarget, {
      whitelist: this.whitelistValue,
      dropdown: {
        enabled: 0,
        maxItems: 30,
        highlightFirst: true,
      },
    });

    this.tagify.on("add", (e) => this.saveTagNames(e.detail.tagify));
    this.tagify.on("remove", (e) => this.saveTagNames(e.detail.tagify));

    const tagNamesStr = this.tagNamesTarget.value;
    if (tagNamesStr.length > 0) {
      this.tagify.addTags(tagNamesStr.split(","));
      // console.log(tagNamesStr.split(','))
    }
  }

  saveTagNames(tagify) {
    this.tagNamesTarget.value = tagify.value.map((v) => v.value);
  }
}
